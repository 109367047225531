<template>
  <div id="login">
    <Hero/>
    <div class="login-section">
      <h1>Admin Login</h1>
      <form>
        <div class="input-field">
          <label for="username">Username</label>
          <input id="username" type="text" class="form-control" v-model="username" required>
        </div>
        <div class="input-field">
          <label for="password">Password</label>
          <input id="password" type="password" class="form-control" v-model="password" required>
        </div>
        <div class="alert" v-if="error != null"><span>{{ error.message }}</span></div>
        <div>
          <br/>
          <button v-on:click="login($event)">Login</button> 
        </div>
      </form>
    </div>
    <FollowUs/>
    <BookNow/>
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import FollowUs from '@/components/FollowUs.vue'
import BookNow from '@/components/BookNow.vue'

export default { 
  components: { 
    Hero, 
    FollowUs,
    BookNow
  },
  data () {
    return {
      error: '',
      username: '',
      password: '',
      signedIn: false
    }
  },
  methods: {
    login (event) {
      event.preventDefault()
        this.loading = true;
        this.$cognitoAuth.authenticate(this.username, this.password, (err, result) => {
           if (err) {
              this.error = err;
              this.loading = false;
            } else {
              this.$router.push('/dashboard');
              return result;
            }
        });
    }
  }
}
</script>


<style lang="scss">
#login {
  .login-section {
    width: 65%;
    margin: auto;
    h1 {
      text-align: left;
      margin-top: 50px;
    }
    .alert {
      color: red;
      margin-top: 30px;
    }
    button {
      padding: 10px;
      background-color: #3C6E71;
      color: white;
      margin-top: 20px;
      padding: 10px 20px;
    }
    input {
      border: 3px solid  #3C6E71;
    }
    .input-field {
      margin-top: 30px;
      label {
        margin-right: 10px;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .login-section {
      width: 90%;
      margin: auto;
    }
  }
}
</style>